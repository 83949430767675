import styled from "styled-components";

//Display1 => HeadLine3
export const Display1B = styled.p`
  font-size: 56px;
  line-height: 68px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 40px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Display2 => Title1
export const Display2B = styled.p`
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Headline1 => Title1
export const Headline1B = styled.p`
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Headline2 => Title1
export const Headline2B = styled.p`
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Headline3 => Title1
export const Headline3B = styled.p`
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Headline4 => Title2
export const Headline4B = styled.p`
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 28px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Headline4 => Title2
export const Headline4R = styled.p`
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 28px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Title1 => Caption1
export const Title1B = styled.p`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Title1 => Caption1
export const Title1R = styled.p`
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Title2 => Caption1
export const Title2B = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Title2 => Caption1
export const Title2R = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Body1 => Caption1
export const Body1B = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Body1 => Caption1
export const Body1R = styled.p`
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: "Pretendard";
  ${(props) => props.width && `width : ${props.width}`};
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Caption1 => Caption1
export const Caption1B = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Caption1 => Caption1
export const Caption1R = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    color: ${(props) => props.mobile_color && `${props.mobile_color}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;

//Caption2 => Caption2
export const Caption2R = styled.p`
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;

//Caption2 => Caption2
export const Caption2B = styled.p`
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Caption3 => Caption3
export const Caption3B = styled.p`
  font-size: 11px;
  line-height: 14px;
  font-weight: 700;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Caption3 => Caption3
export const Caption3R = styled.p`
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;
//Caption4 => ?
export const Caption4R = styled.p`
  font-size: 9px;
  line-height: 12px;
  font-weight: 400;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;

//Button1 => Caption1
export const Button1R = styled.p`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;

//Button1 => Caption1
export const Button1M = styled.p`
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;

//Button2 => Button1
export const Button2R = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Pretendard";
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;

//예외 : 매칭이 안되는 폰트들 직접 설정.
export const Customer = styled.p`
  ${(props) => props.font && props.font};
  word-break: ${(props) =>
    props.wordBreak ? `${props.wordBreak}` : "keep-all"};
  color: ${(props) => props.color && `${props.color}`};
  margin: ${(props) => (props.margin ? `${props.margin}` : 0)};
  text-align: ${(props) => props.textAlign && `${props.textAlign}`};
  @media (max-width: 767px) {
    ${(props) => props.mobile_font && props.mobile_font};
    margin: ${(props) => props.mobile_margin && `${props.mobile_margin}`};
    text-align: ${(props) =>
      props.mobile_textAlign && `${props.mobile_textAlign}`};
  }
`;

//Customer에서 사용할 FontType
export const FontType = {
  Display1B: `font-size: 56px; line-height: 68px; font-weight: 700; font-family: "Pretendard";`,
  Display2B: `font-size: 48px; line-height: 56px; font-weight: 700; font-family: "Pretendard";`,
  Headline1B: `font-size: 40px; line-height: 50px; font-weight: 700; font-family: "Pretendard";`,
  Headline2B: `font-size: 36px; line-height: 44px; font-weight: 700; font-family: "Pretendard";`,
  Headline3B: `font-size: 32px; line-height: 40px; font-weight: 700; font-family: "Pretendard";`,
  Headline4B: `font-size: 28px; line-height: 36px; font-weight: 700; font-family: "Pretendard";`,
  Headline4R: `font-size: 28px; line-height: 36px; font-weight: 400; font-family: "Pretendard";`,
  Title1B: `font-size: 24px; line-height: 32px; font-weight: 700; font-family: "Pretendard";`,
  Title1R: `font-size: 24px; line-height: 32px; font-weight: 400; font-family: "Pretendard";`,
  Title2B: `font-size: 20px; line-height: 28px; font-weight: 700; font-family: "Pretendard";`,
  Title2R: `font-size: 20px; line-height: 28px; font-weight: 400; font-family: "Pretendard";`,
  Body1B: `font-size: 16px; line-height: 22px; font-weight: 700; font-family: "Pretendard";`,
  Body1R: `font-size: 16px; line-height: 22px; font-weight: 400; font-family: "Pretendard";`,
  Caption1B: `font-size: 14px; line-height: 20px; font-weight: 700; font-family: "Pretendard";`,
  Caption1R: `font-size: 14px; line-height: 20px; font-weight: 400; font-family: "Pretendard";`,
  Caption2R: `font-size: 13px; line-height: 18px; font-weight: 400; font-family: "Pretendard";`,
  Caption2B: `font-size: 13px; line-height: 18px; font-weight: 700; font-family: "Pretendard";`,
  Caption3R: `font-size: 11px; line-height: 14px; font-weight: 400; font-family: "Pretendard";`,
  Button1R: `font-size: 18px; line-height: 22px; font-weight: 400; font-family: "Pretendard";`,
  Button1M: `font-size: 18px; line-height: 22px; font-weight: 500; font-family: "Pretendard";`,
  Button2R: `font-size: 16px; line-height: 20px; font-weight: 400; font-family: "Pretendard";`,
  Button3B: `font-size: 14px; line-height: 20px; font-weight: 700; font-family: "Pretendard";`,
  Button3R: `font-size: 14px; line-height: 20px; font-weight: 400; font-family: "Pretendard";`,
};

const Font = {
  Display1B,
  Display2B,
  Headline1B,
  Headline2B,
  Headline3B,
  Headline4B,
  Headline4R,
  Title1B,
  Title1R,
  Title2B,
  Title2R,
  Body1B,
  Body1R,
  Caption1B,
  Caption1R,
  Caption2B,
  Caption2R,
  Caption3B,
  Caption3R,
  Caption4R,
  Button1R,
  Button1M,
  Button2R,
  Customer,
  FontType,
};
export default Font;
