import { atom, createStore } from "jotai";

export const atoms = {
  header_scroll: atom(true),
  header_scroll_style: atom(false),
  nav_focus_index: atom(false),
  show_toggle: atom(false),
  pop_up: atom(false),
  event_floting: atom(false),
};

export const store = createStore();
