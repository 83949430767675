import React, { createContext, useContext } from "react";
import { useMediaQuery } from "react-responsive";

const MySizeContext = createContext();

export const MySizeProvider = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
  const isDeskTop = useMediaQuery({ minWidth: 1200 });
  const bannerHeight = '0px';
  const headerHeight = isMobile ? '44px' : '80px';

  return (
    <MySizeContext.Provider value={{ isMobile, isTablet, isDeskTop, bannerHeight, headerHeight }}>
      {children}
    </MySizeContext.Provider>
  );
};

export const useMySize = () => useContext(MySizeContext);
