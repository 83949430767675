import React from "react";
import PopUp from "./PopUp";
import EventFloting from "./EventFloating";

const Modal = () => {
  return (
    <>
      {/* !! 임시: 팝업 안보이게 */}
      {/* <PopUp /> */}
      {/* <EventFloting /> */}
    </>
  );
};

export default Modal;