export const Color = {
  main100: "#E3E0FF",
  main200: "#C8C2FF",
  main300: "#ACA3FF",
  main400: "#9185FF",
  main500: "#7566FF",
  main600: "#5E52CC",
  main700: "#463D99",
  main800: "#2F2966",
  main900: "#171433",

  point100: "#FFE4E7",
  point200: "#FFC9CF",
  point300: "#FFADB7",
  point400: "#FF929F",
  point500: "#FF7787",
  point600: "#CC5F6C",
  point700: "#994751",
  point800: "#663036",
  point900: "#33181B",

  gray100: "#FFFFFF",
  gray150: "#F4F4F4",
  gray200: "#E4E4E4",
  gray300: "#BCBCBC",
  gray400: "#878787",
  gray500: "#5E5E5E",
  gray600: "#383838",
  gray700: "#000000",

  secondary100: "#D3D3D4",
  secondary200: "#A7A6A9",
  secondary300: "#7A7A7E",
  secondary400: "#4E4E52",
  secondary500: "#222127",
  secondary600: "#1B1B1F",
  secondary700: "#141418",
  secondary800: "#0E0D10",
  secondary900: "#070708",

  systemError: "#EF3636",
  systemPositive: "#4383FF",
  systemPositiveLight: "#DEEAFF",
  systemInfo: "#FFBB54",
};
export default Color;
