import styled, {css} from "styled-components";

const Button = styled.button`
  border:0;
  background-color: transparent;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

export default Button;