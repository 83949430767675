
export const shadow1 = 'drop-shadow(10px 10px 30px #463D991A)';
export const shadow2 = 'drop-shadow(8px 12px 20px #17143333)';
export const shadow3 = 'drop-shadow(0px 15px 24px #00000026)';
export const shadow4 = 'drop-shadow(8px 8px 20px #00000033)';
export const shadow5 = 'drop-shadow(4px 4px 8px #00000040)';

export const new_shadow2 = `
box-shadow: 8px 12px 20px #17143333;
 -moz-box-shadow: 8px 12px 20px #17143333;
 -webkit-box-shadow: 8px 12px 20px #17143333;
 `;

export const new_shadow4 = `
box-shadow: 8px 8px 20px #00000033;
 -moz-box-shadow: 8px 8px 20px #00000033;
 -webkit-box-shadow: 8px 8px 20px #00000033;
 `;

const Shadow = { shadow1, shadow2, shadow3, shadow4, shadow5, new_shadow4 }

export default Shadow;
