import styled from "styled-components";

export const ContentsStyled = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1040px;
  margin: 0 80px;
  box-sizing: border-box;
  @media (max-width: 1199px){
    padding: 0 48px;
    margin: 0;
  }
  @media (max-width: 767px){
    padding: 0 16px;
    margin: 0;
  }
`;